/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import Link from 'next/link';
import { getCompareList, getCustomerUid } from '@core_modules/productcompare/service/graphql';
import { getLoginInfo } from '@helper_auth';
import { getCookies } from '@helper_cookies';

const compare = () => {
    const isLogin = getLoginInfo();
    const reloadCompareQty = typeof window !== 'undefined' && window && window.reloadCompareQty;

    const [getProduct, {
        data, loading, refetch: refetchCompare,
    }] = getCompareList();
    const [getUid, { data: dataUid }] = getCustomerUid();

    React.useEffect(() => {
        if (isLogin) {
            getUid();
        }
    }, [isLogin]);

    React.useEffect(() => {
        if (!isLogin) {
            const uid = getCookies('uid_product_compare');
            if (uid) {
                getProduct({
                    variables: {
                        uid,
                    },
                });
            }
        }
    }, []);

    React.useEffect(() => {
        if (dataUid && dataUid?.customer?.compare_list && isLogin) {
            const uid = getCookies('uid_product_compare');
            if (uid) {
                const uid_product = dataUid?.customer?.compare_list?.uid;
                getProduct({
                    variables: {
                        uid: uid_product,
                    },
                });
            }
        }
    }, [dataUid]);

    React.useEffect(() => {
        if (reloadCompareQty) {
            refetchCompare();
            window.reloadCompareQty = false;
        }
    }, [reloadCompareQty]);

    return (
        <>
            { (!loading && data?.compareList?.item_count) ? (
                <Link href="/catalog/product_compare">
                    <div
                        className={cx('group hover:cursor-pointer')}
                    >
                        <span className="compare-icon text-neutral-black hover:before:text-yellow-300" />
                        <style jsx>
                            {`
                        .compare-icon:before {
                            display: block;
                            content: "\e968";
                            font-family: var(--icons-urbanicon-fossil-theme);
                            font-size: 1.75rem;
                            line-height: 1.75rem;
                            width: 1.75rem;
                            height: 1.75rem;
                        }
                        @media only screen and (max-width: 767.9px) {
                            .compare-icon:before {
                                font-size: 25px;
                                line-height: 25px;
                                width: 25px;
                                height: 25px;
                            }
                        }
                    `}
                        </style>
                    </div>
                </Link>
            ) : null}
        </>
    );
};

export default compare;
